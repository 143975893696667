import React, { useState, useEffect } from "react";
import "./inputCss.scss";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

const FileUpload = (data) => {
  const [fileName, setFileName] = useState("");
  const [lastFile, setLastFile] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    let supportedFiles = [
      "pdf",
      "png",
      "jpg",
      "jpeg",
      "tiff",
      "bmp",
      "wav",
      "mp3",
    ];
    const fileParts = file.name.split(".");
    const format = fileParts.pop(); // File extension
    if (supportedFiles.indexOf(format.toLowerCase()) !== -1) {
      if (file) {
        setFileName(file.name);
        setSelectedFile(file);
        handleFileUpload(file);
      } else {
        setFileName("");
        setSelectedFile(null);
      }
    } else {
      event.target.value = "";
      toast.dismiss();
      toast.error(`${format} is not supported!`);
    }
  };

  useEffect(() => {
    setLastFile(data.value);
  }, [data]);
  useEffect(() => {
    setSelectedFile("");
    setFileName("");
  }, [data.selectedDate]);

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleFileUpload = async (file) => {
    let today = new Date();
    let date = moment(today).format("YYYY/MM/DD");
    let prevDate = moment(data.prevDateTime).format("YYYY/MM/DD");
    let epoch = moment(today).valueOf();
    if(date !== prevDate){
      epoch = moment(prevDate).valueOf();
      date = prevDate;
    }
    
    let relativeFolderPath =
      data.data.rid + "/" + data.componentId + "/" + data.props.id;
    const fileParts = file.name.split(".");
    const name = fileParts.slice(0, -1).join("."); // File name (excluding extension)
    const format = fileParts.pop(); // File extension
    let base64blob = await blobToBase64(file);
   
    let fileInfo = {
      name: data.props.id,
      value: name + "_" + epoch + "." + format,
      vid: data.data.vid,
      code: atob(localStorage.getItem("fnKey")),
      base64: base64blob,
      format: format,
      fType: file.type,
      fileName: name,
      relativeFolderPath: relativeFolderPath,
      onlineFile: file
    };
    data.onChange(fileInfo);
  };

  return (
    <div className="file-upload-container">
      <input
        type="file"
        id={"fileInput" + data.props.id}
        onChange={handleFileChange}
        className="file-upload-input"
        name={data.name}
        accept=".pdf, .png, .jpg, .jpeg, .tiff, .bmp, .wav, .mp3"
      />
      <label
        htmlFor={"fileInput" + data.props.id}
        className="file-upload-button"
      >
        Choose File
      </label>

      <p className="file-upload-name">
        {fileName ? `Selected file: ${fileName}` : "No file selected"}
        <br></br>
        {lastFile && fileName == "" ? `Last uploaded: ${lastFile}` : ""}
      </p>
    </div>
  );
};

export default FileUpload;
