import React, { useContext, useState, useEffect } from "react";

import ReactDOM from "react-dom";
//import { BrowserRouter, Route, Switch } from 'react-router-dom';
import "./index.css";
import App from "./containers/App.js";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./fonts/font.woff";
//theme
import "primereact/resources/themes/mdc-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";

fetch("/appConfig/msalContextpath.json", {
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Accept: "application/json",
  },
})
  .then((res) => res.json())
  .then((data) => {
    localStorage.setItem("appConfig", JSON.stringify(data));
    localStorage.setItem("QLep", atob(data.graphQlendpoint));
    localStorage.setItem("ApiEndPoint", data.apiEndpoint);
    localStorage.setItem("bannerURL", data.bannerURL);
    localStorage.setItem("fnKey", data.fnKey);
  }).catch(error=>{
    console.log(error)
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
