import React, { useEffect, createContext, useState, useContext } from "react";
import "./Dashboard.scss";
import { useHistory } from "react-router-dom";
import Messages from "../Messages/Messages";
import Loader from "react-loader-spinner";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import Sidenav from "./Sidenav";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import PinInput from "react-pin-input";
import { Button, Container, Row, Col, Modal, Spinner } from "react-bootstrap";
import useNetwork from "../../customHooks/useNetwork";
import { ToastContainer, toast } from "react-toastify";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
// import { loginRequest } from "../../helpers/authConfig";
import { callMsGraph } from "../../helpers/graph";
import { useIsAuthenticated } from "@azure/msal-react";
import { postComments, uploadOpsFile } from "../Comments/CommentService";
import moment from "moment";
import db from "../../DexieDBConfig";
const Customer_query = gql`
  query {
    customersByUser {
      userId
      customers {
        customerId
        customerName
        customerCode
        customerType
        customerDisplayName
      }
    }
  }
`;

export const MyContext = createContext();
let custId;
/*istanbul ignore next */

const Dashboard = (props) => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  let alreadyEnrolled = false;
  let [name, setName] = useState("");
  let [customerId, setCustomerId] = useState(0);
  let [vesselName, setVesselName] = useState("");
  let [vesselImage, setVesselImage] = useState("");
  let [bgColor, setBgColor] = useState("#224691");
  let [customers, setCustomers] = useState([]);
  let [pinSetter, setPinSetter] = useState(false);
  let [userEnrolled, setUserEnrolled] = useState(false);
  let [pin, setPin] = useState("");
  let [selectedUser, setSelectedUser] = useState({ pin: "", response: {} });
  const [id_token, setToken] = useState("");
  const [userReceived, setUserReceived] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [serviceRecordList, setServiceRecordList] = useState([]);
  const [cid, setCustid] = useState();
  const networkState = useNetwork();
  const { online } = networkState;
  const [offlineEnabledUser, setOfflineEnabledUser] = useState(false);
  const [downLoadInProgress, setDownLoadInProgress] = useState(false);
  const [receivedMetaTrue, setReceivedMetaTrue] = useState(false);
  const [custName, setCustName] = useState("");
  const [vesselLevel, setVesselLevel] = useState(false);
  const [vesselId, setVesselId] = useState(0);
  const [vesselIMO, setVesselIMO] = useState(0);
  const [graphData, setGraphData] = useState(null);
  const { instance, accounts } = useMsal();
  const [uploadInprogress, setUploadInprogress] = useState(false);
  useEffect(() => {
    if (userReceived) {
      setDeviceStatus();
    }
  }, [online]);
  let history = useHistory();


  const [getCustomers, { loading, error, data }] = useLazyQuery(
    Customer_query,
    {
      fetchPolicy: "cache-and-network",
      onCompleted(resp) {
        if (resp && resp.customersByUser) {
          localStorage.setItem("user_uid", resp.customersByUser.userId);
          db.userInfo.toArray().then((users) => {
            users.map((user) => {
              if (user.email == localStorage.getItem("user_email")) {
                if (user.uid !== resp.customersByUser.userId) {
                  let curUser = user;
                  curUser.uid = resp.customersByUser.userId;
                  db.userInfo.put(
                    curUser,
                    localStorage.getItem("user_email")
                  );
                }
                setSelectedUser(user);
              }
            });
            
          });
          let offlineAvailable = false;
          let data = selectedUser;
          let custIds = [];
          resp.customersByUser.customers.map((cust) => {
            custIds.push(cust.customerId);
          });
          if (selectedUser.pin.length !== 0) {
            data["response"]["customersByUser"] = resp.customersByUser.customers;
            db.userInfo.toArray().then((users) => {
              users.map((user) => {
                if (user.email == localStorage.getItem("user_email")) {
                  setOfflineEnabledUser(true);
                  if (user.response.customersByUser.length == 0) {
                    db.userInfo.put(data, localStorage.getItem("user_email"));
                  }
                  user.response.customersByUser.map((cust, pos) => {
                    if (custIds.indexOf(cust.customerId) == -1) {
                      user.response.customersByUser.splice(pos, 1);
                      db.userInfo.put(data, localStorage.getItem("user_email"));
                    }
                  });
                }
              });
            });

            db.versionInfo.toArray().then((versions) => {
              let customersV = [];
              let check = 0;
              versions.map((version) => {
                if (version.email == localStorage.getItem("user_email")) {
                  check++;
                }
              });
              if (check == 0) {
                resp.customersByUser.customers.map((cust) => {
                  let item = {
                    id: cust.customerId,
                    version: 0,
                    vessels: {},
                  };
                  customersV.push(item);
                });
                let data = {
                  email: localStorage.getItem("user_email"),
                  customers: customersV,
                };
                db.versionInfo
                  .add(data, localStorage.getItem("user_email"))
                  .then((event) => {
                  });
              }
            });
          }
          if (resp.customersByUser.customers.length === 1) {
            let { customerId } = resp.customersByUser.customers[0];
            setVesselLevel(false);
            setCustid(customerId);
            // getVersionData({
            //   variables: {
            //     customerId: +customerId,
            //   },
            // });
            db.userInfo.toArray().then((users) => {
              users.map((user) => {
                if (user.email == localStorage.getItem("user_email")) {
                  if (
                    user.response.customersByUser.length == 0 ||
                    user.response.customersByUser[0].vessels == undefined
                  ) {
                    getCustMetaData(customerId);
                  }
                }
              });
            });
            setCustomerId(customerId);
            setCustomers(resp.customersByUser.customers);
            localStorage.setItem("singleCustomer", "true");
            localStorage.setItem(
              "vesselHome",
              `/customers/${customerId}/vessel/`
            );
            if (window.location.hash.indexOf("vessel") == -1) {
              history.push(`/customers/${customerId}/vessel/`);
            }
          } else {
            localStorage.setItem("singleCustomer", "false");
            setCustomers(resp.customersByUser.customers);
            if (window.location.hash.indexOf("vessel") == -1) {
              history.push("/customers");
            }
          }
        }
      },
    }
  );

  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);

    if (online) {
      db.images.toArray().then((images) => {
        images.map((img) => {
          if (moment(new Date()).diff(moment(img.time), "days") > 2) {
            db.images.delete(img.id);
          }
        });
      });
      

      db.syncedOperations.toArray().then((synOps) => {
        synOps.map((each) => {
          if (each.finishedTime == "Invalid date") {
            db.syncedOperations.delete(each.valueId);
          } else if (each.UTCtimestamp) {
            if (
              moment(new Date()).diff(moment(each.UTCtimestamp), "days") > 7
            ) {
              db.syncedOperations.delete(each.valueId);
            }
          } else if (each.finishedTime) {
            if (
              moment(new Date()).diff(moment(each.finishedTime), "days") > 7
            ) {
              db.syncedOperations.delete(each.valueId);
            }
          }
        });
      });

 

      //remove older offline comments
      db.comments.toArray().then((comms) => {
        comms.map((comm) => {
          let date = comm.slice(-10);
          if (moment(new Date()).diff(moment(date), "days") > 7) {
            db.comments.delete(comm.id);
          }
        });
      });

      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: accounts[0],
        })
        .then((response) => {
          callMsGraph(response.accessToken).then((resp) => {
            localStorage.setItem("user_email", resp.mail || "");
            localStorage.setItem("user_uid", resp.id);
            localStorage.setItem("user_name", resp.displayName);
            setGraphData(resp);
            setUserReceived(true);
            setDeviceStatus();
            setName(resp.displayName);
            db.userInfo.toArray().then((users) => {
              setUserEnrolled(false);
              users.map((user) => {
                if (user.email == localStorage.getItem("user_email")) {
                  alreadyEnrolled = true;
                  if (user.uid == undefined) {
                    let curUser = user;
                    curUser.uid = resp.data.uid;
                    db.userInfo.put(
                      curUser,
                      localStorage.getItem("user_email")
                    );
                  }
                  setSelectedUser(user);
                }
              });
              if (!alreadyEnrolled) {
                confirmAlert({
                  title: "Offline Enrollment",
                  message: "Do you want to enable offline login?",
                  overlayClassName: "confirm-overlay",
                  closeOnEscape: false,
                  closeOnClickOutside: false,
                  buttons: [
                    {
                      label: "Yes",
                      onClick: () => {
                        setPinSetter(true);
                      },
                    },
                    {
                      label: "No",
                      onClick: () => {
                        getCustomers();
                      },
                    },
                  ],
                });
              } else {
                getCustomers();
              }
            });
          });
        })
        .catch((error) => {
          const accessTokenRequest = {
            scopes: ["user.read"],
            account: accounts[0],
          };
          instance
            .acquireTokenSilent(accessTokenRequest)
            .then(function (accessTokenResponse) {
              // Acquire token interactive success
              let accessToken = accessTokenResponse.accessToken;
              return accessToken;
              // Call your API with token
            })
            .catch(function (error) {
              // Acquire token interactive failure
              instance.loginRedirect({ scopes: ["User.Read"] }).catch((e) => {
                console.log(e);
              });
            });
        });
    }

    if (!online) {
      setUserReceived(true);
      offlineCustomerSetup();
    }
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  }, []);

  function objectToFormData(obj) {
    const formData = new FormData();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        formData.append(key, obj[key]);
      }
    }
    return formData;
  }

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
    if (window.navigator.onLine) {
      db.files.toArray().then((chats) => {
        if (chats.length > 0) {
          let calls = 0;
          chats.map((chat) => {
            if (chat.type == "string") {
              const data = new FormData();
              data.append("comment", chat.content);
              data.append("time", chat.time);
              postData(data, chat).then(() => {
                calls = calls + 1;
                if (calls == chats.length) {
                  db.files.clear().then(() => {
                  });
                }
              });
            } else {
              const data = new FormData();
              let blob = base64ToBlob(chat.content)
              let file = new File([blob], "imgFile", {
                type: chat.imgType,
              });

              data.append("image", file);
              data.append("time", chat.time);
              postData(data, chat).then(() => {
                calls = calls + 1;
                if (calls == chats.length) {
                  db.files.clear().then(() => {
                  });
                }
              });
            }
          });
        }
      });

      /**Upload the files when online */
      db.uploadFiles.toArray().then((files) => {

        if(files.length > 0){
          let allfiles = [];
          files.map(file=>{
            let fff = base64ToBlob(file.data.base64, file.data.fType);
            let fileNew = new File([fff], file.value, {
              type: file.data.fType,
            });
            const formDataNew = new FormData();
            formDataNew.append("file", fileNew);
            formDataNew.append("filePath", file.filePath);
            formDataNew.append("type", "vsensorupload");
            formDataNew.append("relativeFolderPath", file.data.relativeFolderPath);

            let toUpload = {
              file: formDataNew,
              vid: file.data.vid,
              code: file.data.code,
              gid: file.gid,
              virId: file.data.name,
              fileName: file.data.value,
              uploadId: file.id
            }
            allfiles.push(toUpload);
          });
          uploadMultipleFiles(allfiles);
        }
      })
    }
  };

  const uploadFile = async (file, vid, code, gid, virId, name, id) => {
    try {
      const response = await uploadOpsFile(vid, code, file);
    } catch (error) {
      let toBeUpdated;
      db.unsyncedOperations.toArray().then((data)=>{
        data.map(each=>{
          if(each.valueId == gid){
            toBeUpdated = each;
            delete toBeUpdated.value[virId]
            db.unsyncedOperations.put(toBeUpdated, gid);
            db.uploadFiles.delete(id);

            toast.error(`${name} upload failed. Please try again!`);
          }
        })
      });
      throw {gid, virId}
    }
  }

  const uploadMultipleFiles = async (files) => {
    setUploadInprogress(true);
    const uploadPromises = files.map((file) =>
      uploadFile(
        file.file,
        file.vid,
        file.code,
        file.gid,
        file.virId,
        file.fileName,
        file.uploadId
      )
    );
    try {
      // Use Promise.all to wait for all file uploads to finish
      await Promise.all(uploadPromises);
      setUploadInprogress(false);
      db.uploadFiles.clear().then(() => {});
    } catch (error) {

      setUploadInprogress(false);
    }
  };

  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  useEffect(() => {
    if (!online) {
      setUserReceived(true);
      offlineCustomerSetup();
    } else {
      localStorage.removeItem("offline_user");
    }
  }, [online]);
  const pinCheck = (evt) => {
    setPin(evt);
  };

  const offlineCustomerSetup = () => {
    setCustomers([]);
    db.userInfo.toArray().then((users) => {
      users.map((user) => {
        let theUser = localStorage.getItem("offline_user_email")
          ? localStorage.getItem("offline_user_email")
          : localStorage.getItem("user_email");
        if (user.email == theUser) {
          localStorage.setItem("user_uid", user.uid);
          let resp = user.response;

          if (resp.customersByUser.length === 1) {
            let { customerId } = resp.customersByUser[0];
            setName(user.userName.split(" ")[0]);
            setCustomerId(customerId);
            setCustomers(resp.customersByUser);
            localStorage.setItem("singleCustomer", "true");
            localStorage.setItem(
              "vesselHome",
              `/customers/${customerId}/vessel/`
            );
            if (window.location.hash.indexOf("vessel") == -1) {
              history.push(`/customers/${customerId}/vessel/`);
            }
          } else {
            localStorage.setItem("singleCustomer", "false");
            setName(user.userName.split(" ")[0]);
            setCustomers(resp.customersByUser);
            if (window.location.hash.indexOf("vessel") == -1) {
              history.push("/customers");
            }
          }
        }
      });
    });
  };

  useEffect(() => {
    if (receivedMetaTrue) {
      setTimeout(() => {
        setReceivedMetaTrue(false);
      }, 3000);
    }
  }, [receivedMetaTrue]);

  const postData = async (comment, chat) => {
    try {
      const result = await postComments(
        chat.cid,
        chat.vid,
        chat.rid,
        comment,
        atob(localStorage.getItem("fnKey"))
      );
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
    }
  };

  const getCustMetaData = (id, name = "", vesselLevel = false, vessId = 0) => {
    custId = id;
    setCustName(name);
    setCustid(undefined);
    setVesselLevel(vesselLevel);
    setVesselId(vessId);
    setTimeout(() => {
      setCustid(id);
    }, 250);
  };

  let versionCheck = gql`
    query customerVSensorHierarchyVersion($customerId: ID!) {
      customerVSensorHierarchyVersion(customerId: $customerId) {
        version
      }
    }
  `;
  let metaQuery = gql`
    query vesselMetadata($customerId: ID!) {
      vesselMetadata(customerId: $customerId) {
        id
        displayName
        rooms {
          id
          name
          customerId
          components {
            id
            name
            Sensor {
              id
              name
              properties {
                type
                options
              }
            }
          }
        }
      }
    }
  `;

  let vesselMetaQuery = gql`
    query vesselMetadata($customerId: ID!, $vesselId: ID!) {
      vesselMetadata(customerId: $customerId, vesselId: $vesselId) {
        id
        displayName
        rooms {
          id
          name
          customerId
          components {
            id
            name
            Sensor {
              id
              name
              properties {
                type
                options
              }
            }
          }
        }
      }
    }
  `;
  useEffect(() => {
    if (cid) {
      getVersionData({
        variables: {
          customerId: +cid,
        },
      });

      db.userInfo.toArray().then((users) => {
        users.map((user) => {
          if (user.email == localStorage.getItem("user_email")) {
            let thisCust = user.response.customersByUser.filter(
              (cust) => cust.customerId == cid
            );
            if (thisCust.length !== 0 && thisCust[0].vessels == undefined) {
              setDownLoadInProgress(true);
              let metaVariables = {
                customerId: +cid,
              };

              if (vesselLevel) {
                metaVariables.vesselId = +vesselId;
              }

              getMetaData({
                variables: metaVariables,
              });
            }
            if (thisCust.length == 0) {
              let newCust = {
                customerId: +cid,
                customerName: custName,
                vessels: {},
              };
              user.response.customersByUser.push(newCust);
              db.userInfo.put(user, localStorage.getItem("user_email"));
              let metaVariables = {
                customerId: +cid,
              };

              if (vesselLevel) {
                metaVariables.vesselId = +vesselId;
              }
              getMetaData({
                variables: metaVariables,
              });
            }
            if (user.response.customersByUser.length == 0) {
              setDownLoadInProgress(true);
              let metaVariables = {
                customerId: +cid,
              };

              if (vesselLevel) {
                metaVariables.vesselId = +vesselId;
              }
              getMetaData({
                variables: metaVariables,
              });
            }
          }
        });
      });
    }
  }, [cid]);

  const [getVersionData, { loadingVersion, errorVersion, dataVersion }] =
    useLazyQuery(versionCheck, {
      fetchPolicy: "network-only",
      onCompleted(resp) {
        if (resp.customerVSensorHierarchyVersion !== null) {
          db.versionInfo.toArray().then((versions) => {
            versions.map((version) => {
              if (version.email == localStorage.getItem("user_email")) {
                let custFound = false;
                version.customers.map((cust) => {
                  if (cust.id == cid) {
                    custFound = true;
                    if (
                      resp.customerVSensorHierarchyVersion.version !==
                      cust.version
                    ) {
                      if (!receivedMetaTrue) {
                        setDownLoadInProgress(true);
                        let metaVariables = {
                          customerId: +cid,
                        };
                        if (vesselLevel) {
                          metaVariables.vesselId = +vesselId;
                          getMetaDataVessel({
                            variables: metaVariables,
                          });
                        } else {
                          getMetaData({
                            variables: metaVariables,
                          });
                        }
                      }

                      cust.version =
                        resp.customerVSensorHierarchyVersion.version;
                      db.versionInfo.put(
                        version,
                        localStorage.getItem("user_email")
                      );
                    }
                  }
                });
                if (!custFound) {
                  let cust = {
                    id: cid,
                    version: resp.customerVSensorHierarchyVersion.version,
                    vessels: {},
                  };
                  version.customers.push(cust);
                  db.versionInfo.put(
                    version,
                    localStorage.getItem("user_email")
                  );
                }
              }
            });
            // let currentUser = versions.filter(ver=>ver.email == localStorage.getItem("user_email"));
            // let currentCust = currentUser.customers.filter(cust=>cust.id == cid);
          });
        }
      },
    });

  const forceUpdateMetaData = (id) => {
    setDownLoadInProgress(true);
    let metaVariables = {
      customerId: id,
    };
    getMetaData({
      variables: metaVariables,
    });
  };
  const [getMetaData, { loadingMeta, errorMeta, dataMeta }] = useLazyQuery(
    metaQuery,
    {
      fetchPolicy: "no-cache",
      onCompleted(resp) {
        processMetaData(resp);
      },
      onError(err) {
        setDownLoadInProgress(false);
        // toast.dismiss();
        // toast.error("Error downloading data!");
      },
    }
  );

  const [getMetaDataVessel, {}] = useLazyQuery(vesselMetaQuery, {
    fetchPolicy: "no-cache",
    onCompleted(resp) {
      processMetaData(resp);
    },
    onError(err) {
      setDownLoadInProgress(false);
      // toast.dismiss();
      // toast.error("Error downloading data!");
    },
  });

  const processMetaData = (resp) => {
    setDownLoadInProgress(false);
    setReceivedMetaTrue(true);
    db.userInfo.toArray().then((users) => {
      users.map((user) => {
        if (user.email == localStorage.getItem("user_email")) {
          user.response.customersByUser.map((cust) => {
            if (cust.customerId == custId && !vesselLevel) {
              cust.vessels = resp.vesselMetadata;
              db.userInfo.put(user, localStorage.getItem("user_email"));
              setSelectedCustomer(cust);
              // toast.dismiss();
              // toast.success("Customer made available offline!");
            } else if (cust.customerId == custId && vesselLevel) {
              let vessels = [];
              if (cust.vessels && cust.vessels.length > 0) {
                cust.vessels.map((vessel, i) => {
                  if (vessel.id == vesselId) {
                    cust.vessels[i] = resp.vesselMetadata[0];
                  }
                });
              }
              // vessels.push(resp.vesselMetadata[0]);
              // cust.vessels = vessels;
              db.userInfo.put(user, localStorage.getItem("user_email"));
              setSelectedCustomer(cust);
            }
          });
        }
      });
    });
  };

  const enrollOffline = () => {
    if (pin.length == 5) {
      setSelectedUser({
        email: localStorage.getItem("user_email"),
        uid: localStorage.getItem("user_uid"),
        userName: localStorage.getItem("user_name"),
        pin: pin,
        timeStamp: new Date(),
        response: {
          customersByUser: {},
        },
      });
      let data = {
        email: localStorage.getItem("user_email"),
        uid: localStorage.getItem("user_uid"),
        userName: localStorage.getItem("user_name"),
        pin: pin,
        timeStamp: new Date(),
        response: {
          customersByUser: [],
        },
      };
      let user = localStorage.getItem("user_email");
      db.userInfo.add(data).then((event) => {
        getCustomers();
        setPinSetter(false);
      });
    }
  };

  const value = {
    name,
    setCustomerId,
    customerId,
    vesselName,
    setVesselName,
    vesselImage,
    setVesselImage,
    customers,
    setCustomers,
    setBgColor,
    selectedCustomer,
    setSelectedCustomer,
    getCustMetaData,
    offlineEnabledUser,
    forceUpdateMetaData,
    serviceRecordList,
    setServiceRecordList,
    vesselIMO,
    setVesselIMO,
  };

  // useEffect(() => {
  //   if (id_token !== "") {
  //     getIDStatus();
  //   }
  // }, [id_token]);

  const setDeviceStatus = () => {
    if (window.navigator.onLine) {
      // service.getUser().then((user) => {
      //   if (user) {
      //     if (
      //       localStorage.getItem("offline_user_email") &&
      //       localStorage.getItem("offline_user_email") !== "" &&
      //       user.profile.email !== localStorage.getItem("offline_user_email")
      //     ) {
      //       if (new Date(user.expires_at * 1000) > new Date()) {
      //         localStorage.removeItem("offline_user_email");
      //         localStorage.removeItem("user_email");
      //         // service.logout();
      //       } else {
      //         // service.removeUser().then((data) => {
      //         //   history.push("/");
      //         //   service.login();
      //         // });
      //       }
      //       // service.getUser().then((user) => {
      //       //   if (user) {
      //       // setToken(user.id_token);
      //       //   }
      //       // });
      //     }
      //   } else {
      //     // history.push("/");
      //     // service.login();
      //   }
      // });
    }
  };

  const handleClose = () => {
    setPinSetter(false);
    getCustomers();
  };

  return (
    <MyContext.Provider value={value}>
      <div className="dashboard">
        {/* <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        /> */}
        {uploadInprogress ? (
          <div className="file-loader">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2em", position: "relative", top: "50%" }}
            ></i>
          </div>
        ) : null}
        {downLoadInProgress ? (
          <div className="download-loader">
            <div className="wrapper">
              <Spinner animation="grow" variant="dark" />
              &nbsp;
              <Spinner animation="grow" variant="dark" />
              &nbsp;
              <Spinner animation="grow" variant="dark" />
              <p>Downloading vessel data. This might take few seconds...</p>
            </div>
          </div>
        ) : null}

        <Sidenav bgColor={bgColor} />
        {error && <Messages data={error} />}
        {loading && (
          <div className="loader">
            <Loader
              type="TailSpin"
              color=" #2546B1"
              height={80}
              width={80}
              timeout={3000000000000}
            />
          </div>
        )}
        <Modal
          backdrop="static"
          show={pinSetter}
          size="lg"
          centered
          onHide={handleClose}
          className="pin-model"
        >
          <Modal.Header closeButton>
            <Modal.Title>Offline enrollment</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <h4 className="mb-3">
                Please provide a new pin for enabling offline login
              </h4>
              <PinInput length={5} focus type="numeric" onChange={pinCheck} />
              <h6 className="text-center mt-3">
                Please use this pin to login when the device is offline.
              </h6>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={enrollOffline}
              disabled={pin.length < 5}
            >
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>
        {props.children}
      </div>
    </MyContext.Provider>
  );
};

export default Dashboard;
