import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  BsChevronLeft,
  BsPlusCircle,
  BsX,
  BsPlay,
  BsCaretDown,
  BsClock,
  BsCheck,
} from "react-icons/bs";
import DatePicker from "../Calender/DatePicker";
import moment from "moment";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import { Message } from "primereact/message";
import Loader from "react-loader-spinner";
import { makeStyles } from "@material-ui/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { InputTextarea } from "primereact/inputtextarea";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import TextField from "@material-ui/core/TextField";
import Time from "../../shared/components/Time";
import MobileDateTimePicker from "@material-ui/lab/MobileDateTimePicker";
import { ToastContainer, toast } from "react-toastify";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./Event.scss";
const EVENTS_QUERY = gql`
  query {
    operationalEventTypes {
      id
      name
      displayName
      eventTypeCode
    }
  }
`;
const STATUS_QUERY = gql`
  query {
    operationalEventStatus {
      statusName
      statusCode
    }
  }
`;
const SAVE_EVENT_QUERY = gql`
  mutation saveOperationalEvent(
    $vesselId: ID!
    $operationalEvent: [OperationalEventInput!]
  ) {
    saveOperationalEvent(
      vesselId: $vesselId
      operationalEvent: $operationalEvent
    )
  }
`;

const MARKERS_FETCH = gql`
  query getOperationalEventsByVessel(
    $vesselId: ID!
    $customerId: ID!
    $startTime: Float!
    $endTime: Float!
  ) {
    getOperationalEventsByVessel(
      vesselId: $vesselId
      customerId: $customerId
      startTime: $startTime
      endTime: $endTime
    ) {
      correlationid
      events {
        id
        name
        status
        eventTime
      }
    }
  }
`;

const getTime = () => {
// console.log("time caaled");
  return moment().format("L");
};

const createUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
const getTimeGap = (t1, t2) => {
  var m1 = moment(t1);
  var m2 = moment(t2);
  var m3 = m2.diff(m1, "minutes");
  var m4 = m2.diff(m1, "h");
  var numdays = Math.floor(m3 / 1440);
  var numhours = Math.floor((m3 % 1440) / 60);
  var numminutes = Math.floor((m3 % 1440) % 60);
  return numdays > 0
    ? numdays + " day(s) " + numhours + "h " + numminutes + "m"
    : numhours > 0
    ? numhours + "h " + numminutes + "m"
    : numminutes > 0
    ? numminutes + "m"
    : "";
};

const Events = (props) => {
  const correlationId = useRef(null);
  const [selectedDate, setSelectedDate] = useState(() => getTime());
  const [sameDate, setSameDate] = useState(() => false);
  const [selectScreen, setSelectScreen] = useState(false);
  const [addScreen, setAddScreen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [startDescription, setStartDescription] = useState("");
  const [stopDescription, setStoptDescription] = useState("");
  const [currentTimeValue, setCurrentTimeValue] = useState([null, null]);
  const [openClock, setOpenClock] = useState(false);
  const [completedStep, setCompletedStep] = useState([false, false]);
  const [expandedStep, setExpandedStep] = useState([false, false]);
  const [saveEnable, setSaveEnable] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [markersList, setMarkersList] = useState({});
  const [activeStep, setActiveStep] = React.useState(-1);
  const {
    match: { params },
  } = props;
  const { vid, cid } = params;
  const { loading: eventsLoding, error, data: events } = useQuery(EVENTS_QUERY);
  const {
    loading: statusLoding,
    error: statusError,
    data: statusList,
  } = useQuery(STATUS_QUERY);
  const [addEvent, { loading: saveLoading, error: saveError }] = useMutation(
    SAVE_EVENT_QUERY,
    {
      onCompleted(resp) {
        toast.dismiss();
        toast.success("Event Added Successfully!");
        setAddScreen(false);
        resetScreen();
        reSetClockTime();
        selectedDay(selectedDate);
      },
    }
  );

  const [
    getMarkers,
    { loading: markersLoading, error: markersError, data: markersListData },
  ] = useLazyQuery(MARKERS_FETCH, {
    fetchPolicy: "no-cache",
    onCompleted(resp) {
      if (resp && resp.getOperationalEventsByVessel) {
        setMarkersList(resp.getOperationalEventsByVessel);
      } else {
        setMarkersList([]);
      }
    },
    onError(err) {
      setMarkersList([]);
    },
  });

  const resetScreen = () => {
    setSelectedEvents([]);
    setCompletedStep([false, false]);
    setStartDescription("");
    setStoptDescription("");
    setActiveStep(-1);
    setSameDate(true);
  };

  const selectedDay = (val) => {
    console.log("time---1", val);
    setSelectedDate(val);
    getEventMarkersByDate(val);
  };

  const getEventMarkersByDate = async (time) => {
    let startUtc = moment.utc(new Date(time).setHours(0, 0, 0, 0)).valueOf();
    let endUtc = moment(new Date(time).setHours(0, 0, 0, 0))
      .add("23", "hours")
      .add("59", "minutes")
      .add("59", "seconds")
      .utc()
      .valueOf();
    try {
      await getMarkers({
        variables: {
          vesselId: +vid,
          customerId: +cid,
          startTime: startUtc,
          endTime: endUtc,
        },
      });
    } catch (e) {
      console.log("error fetching sensor");
    }
  };

  useEffect(() => {
    correlationId.current = null;
  }, [addScreen]);

  useEffect(() => {
    reSetClockTime();
  }, [selectedDate]);

  const reSetClockTime = () => {
    // console.log("time---", selectedDate);
    let date = new Date();
    let calenderSelectdDate = moment(selectedDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    let time = moment(calenderSelectdDate)
      .add(date.getHours(), "hours")
      .add(date.getMinutes(), "minutes");
    setCurrentTimeValue((prevTime) => {
      prevTime[0] = time;
      prevTime[1] = time;
      return [...prevTime];
    });
  };

  useEffect(() => {
   // console.log("currentTimeValue", currentTimeValue); 
  }, [currentTimeValue]);

  useEffect(() => {
    if (completedStep.some((step) => step)) {
      setSaveEnable(true);
    } else {
      setSaveEnable(false);
    }
  }, [completedStep]);

  const goTolList = () => {
    setSelectScreen(false);
    setAddScreen(false);
    reSetClockTime();
    resetScreen();
  };

  const timeChange = (newValue, index) => {
   // console.log("new value", newValue);
    setCurrentTimeValue((prevTime) => {
      prevTime[index] = newValue;
      return [...prevTime];
    });
  };

  const saveEvent = async () => {
    console.log("Saving");
    try {
      await addEvent({
        variables: { vesselId: vid, operationalEvent: selectedEvents },
      });
    } catch (err) {
      toast.dismiss();
      toast.error("Event save failure!");
    }
  };

  const cancelStep = (index) => {
    setCompletedStep((prevCompletedStep) => {
      prevCompletedStep[index] = false;
      return [...prevCompletedStep];
    });
    setActiveStep(-1);

    setSelectedEvents((preSelectedEvents) => {
      preSelectedEvents.splice(index, 1);
      return [...preSelectedEvents];
    });
  };

  const saveStep = (index, statusCode) => {
    // console.log("correlationId.current", correlationId.current);
    if (correlationId.current === null) {
      correlationId.current = createUUID();
    }
    // console.log("correlationId.current", correlationId.current);
    setCompletedStep((prevCompletedStep) => {
      prevCompletedStep[index] = true;
      return [...prevCompletedStep];
    });
    setActiveStep(-1);
    let obj = {
      id: selectedEvent.id,
      description: index === 0 ? startDescription : stopDescription,
      dateTime: moment.utc(currentTimeValue[index]).valueOf(),
      correlationId: correlationId.current,
      status: statusCode,
    };
    // console.log("obj", obj);
    setSelectedEvents((preSelectedEvents) => {
      preSelectedEvents[index] = obj;
      preSelectedEvents = preSelectedEvents.filter((event) => event != null);
      return [...preSelectedEvents];
    });
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <InputTextarea
            rows={2}
            cols={30}
            placeholder="Description"
            value={startDescription}
            onChange={(e) =>
              e.target.value.indexOf("~") == -1
                ? setStartDescription(e.target.value)
                : null
            }
            autoResize
          />
        );
      case 1:
        return (
          <InputTextarea
            rows={2}
            cols={30}
            placeholder="Description"
            value={stopDescription}
            onChange={(e) =>
              e.target.value.indexOf("~") == -1
                ? setStoptDescription(e.target.value)
                : null
            }
            autoResize
          />
        );

      default:
        return "Unknown step";
    }
  }
  /*istanbul ignore next*/
  return (
    <>
      <Container className="conatiner">
        {!selectScreen && !addScreen && (
          <Row className="page-head">
            <Col xs={2}>{/* <BsChevronLeft className="head-icn" /> */}</Col>
            <Col xs={8} className="head-txt">
              <h5>Operational Events</h5>
            </Col>
            <Col xs={2}></Col>{" "}
          </Row>
        )}
        <div
          className="calender-container"
          style={getCalenderStyle(selectScreen, addScreen)}
        >
          <Row className="date-wrapper">
            <Col xs={12} className="calender-col">
              {" "}
              <DatePicker getSelectedDay={selectedDay} endDate={365} />{" "}
            </Col>
          </Row>
        </div>
        {!selectScreen && !addScreen && (
          <>
            <Row className="content-scroll">
              <Col xs={12}>
                {markersLoading && (
                  <div className="loader">
                    <Loader
                      type="TailSpin"
                      color=" #2546B1"
                      height={80}
                      width={80}
                      timeout={300000000000000}
                    />
                  </div>
                )}
                {markersList.length > 0 && !markersLoading ? (
                  <VerticalTimeline layout={"1-column-left"}>
                    {/* {console.log("markersList", markersList)} */}
                    {markersList.map((marker) => {
                      let latestItem = marker.events;
                      if (marker.events.length > 1) {
                        latestItem = marker.events
                          .slice()
                          .sort(
                            (a, b) =>
                              new Date(b.eventTime) - new Date(a.eventTime)
                          );
                      }
                       // console.log("latest", latestItem);
                      // console.log("latest", latestItem);
                      return (
                        <VerticalTimelineElement
                          className="vertical-timeline-element--work"
                          contentStyle={{ color: "#2546b1" }}
                          contentArrowStyle={{
                            borderRight: "10px solid  #ccc",
                          }}
                          key={marker.correlationid}
                          iconClassName="timeIcon"
                          icon={
                            <Time
                              time={moment(
                                latestItem[latestItem.length - 1].eventTime
                              ).format("hh:mm A")}
                            />
                          }
                        >
                          <Row className="list-event-name-row">
                            <Col xs={12}>{latestItem[0].name}</Col>
                          </Row>
                          <Row className="event-status">
                            <Col xs={4}>{latestItem[0].status}</Col>
                            <Col className="time-gap" xs={8}>
                              {getTimeGap(
                                latestItem[latestItem.length - 1].eventTime,
                                latestItem[0].eventTime
                              )}
                            </Col>
                          </Row>
                        </VerticalTimelineElement>
                      );
                    })}
                  </VerticalTimeline>
                ) : (
                  !markersLoading && (
                    <div className="center">
                      <Message
                        severity="info"
                        text="No event markers."
                      ></Message>
                    </div>
                  )
                )}
              </Col>
            </Row>

            <div>
              <BsPlusCircle
                className="add-icn"
                onClick={() => setSelectScreen(true)}
              />
            </div>
          </>
        )}
        {(selectScreen || addScreen) && (
          <Row className="page-head">
            <Col xs={2}>
              {/* <BsX className="head-icn" onClick={() => goTolList()} /> */}
            </Col>
            <Col xs={8}>
              {selectScreen && <h5>Select Event Marker</h5>}{" "}
              {addScreen && <h5>New Event</h5>}
            </Col>
            <Col xs={2}>
              {addScreen && (
                <button
                  className="save-btn"
                  disabled={!saveEnable}
                  onClick={saveEvent}
                >
                  Save
                </button>
              )}
            </Col>{" "}
          </Row>
        )}{" "}
        {!addScreen && selectScreen && (
          <>
            <Row>
              <Col xs={12} className="sub-txt">
                Event marker{" "}
              </Col>
            </Row>
            <Row className="event-list-wrpr">
              <Col xs={12}>
                {eventsLoding && (
                  <div className="loader">
                    <Loader
                      type="TailSpin"
                      color=" #2546B1"
                      height={80}
                      width={80}
                      timeout={300000000000000}
                    />
                  </div>
                )}
                {events &&
                  events.operationalEventTypes.map((event) => (
                    <Row className="list-item" key={event.id}>
                      <Col xs={10} className="item-txt">
                        {event.displayName}{" "}
                      </Col>{" "}
                      <Col xs={2}>
                        <BsPlay
                          className="select-icon"
                          onClick={() => {
                            setAddScreen(true);
                            setSelectScreen(false);
                            setSelectedEvent(event);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          </>
        )}
        {addScreen && (
          <>
            <Row>
              <Col xs={12} className="sub-txt">
                Event marker{" "}
              </Col>
            </Row>
            <Row className="event-edit-wrpr">
              <Col xs={12}>
                {saveLoading && (
                  <div className="loader">
                    <Loader
                      type="TailSpin"
                      color=" #2546B1"
                      height={80}
                      width={80}
                      timeout={300000000000000}
                    />
                  </div>
                )}
                <Row className="list-item">
                  <Col xs={10} className="item-txt">
                    {selectedEvent.displayName}{" "}
                  </Col>
                  <Col xs={2}>
                    <BsCaretDown className="select-icon" />{" "}
                  </Col>
                </Row>
              </Col>

              <Col xs={12}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {statusList &&
                    statusList.operationalEventStatus.map((label, index) => (
                      <Step
                        key={label.statusName}
                        onClick={() => setActiveStep(index)}
                        completed={completedStep[index]}
                        expanded={expandedStep[index]}
                      >
                        <StepLabel>
                          <span className="sts-txt">{label.statusName}</span>{" "}
                          <span
                            className="sts-time"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDateTimePicker
                                value={currentTimeValue[index]}
                                openTo="hours"
                                inputFormat="dd MMM yyyy h:mma"
                                onChange={(newValue) =>
                                  timeChange(newValue, index)
                                }
                                renderInput={(params) => (
                                  <>
                                    <TextField {...params} />
                                  </>
                                )}
                              />
                            </LocalizationProvider>
                            <BsClock
                              onClick={() => {
                                setOpenClock(true);
                              }}
                            />
                          </span>
                        </StepLabel>
                        <StepContent>
                          <Typography>{getStepContent(index)}</Typography>
                          <div>
                            <div>
                              <BsX
                                className="cancel-icn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  cancelStep(index);
                                }}
                              />
                              <BsCheck
                                className="tick-icn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  saveStep(index, label.statusCode);
                                }}
                              />
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                </Stepper>
              </Col>
            </Row>
          </>
        )}
      </Container>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </>
  );
};

const submitBtnStyle = {
  position: "fixed",
  bottom: "26pt",
  left: "50%",
  transform: "translate(-50%, 0)",
  backgroundColor: "#FF6302D1",
  width: "100pt",
  height: "30pt",
  border: "none",
  borderRadius: "40px",
  color: "#fff",
  outline: "none",
};

const getCalenderStyle = (selectScreen, addScreen) => {
  return {
    display: !selectScreen && !addScreen ? "flex" : "none",
  };
};
export default Events;
