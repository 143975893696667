import React, { useState, useEffect, useRef, useContext } from "react";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import { Query } from "react-apollo";
// import {  ApolloProvider } from '@apollo/client';
import Loader from "react-loader-spinner";
import { client } from "../../services/GraphqlService.js";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row, Col } from "react-bootstrap";
import roomImg from "../../assets/room-img.svg";
import "./Rooms.scss";
import { useHistory } from "react-router-dom";
import Messages from "../Messages/Messages";
import { dateDifference } from "../../helpers/Utils";
import { MyContext } from "../Dashboard/Dashboard.js";
import useNetwork from "../../customHooks/useNetwork";
import db from "../../DexieDBConfig.js";

const ADD_SENSOR = gql`
  mutation saveVirtualSensorValue(
    $vesselId: ID!
    $customerId: ID!
    $sensorInput: [VirtualSensorInput!]
  ) {
    saveVirtualSensorValue(
      vesselId: $vesselId
      customerId: $customerId
      sensorInput: $sensorInput
    )
  }
`;

let formdetails = false;

const Rooms = (props) => {
  const networkState = useNetwork();
  const { online } = networkState;

  let { setBgColor, selectedCustomer, getCustMetaData } = useContext(MyContext);
  let updateUnsbmittedData = [];
 


 
  const [allUnsubmitted, setUnSubmitted] = useState([]);
  const [allsubmitted, setSubmitted] = useState([]);
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const [queryCompleted, setQueryCompleted] = useState(true);
  const [syncPending, setSyncPending] = useState(false);
  const [todayDate, setDate] = useState(moment().format("L"));
  const [count, setCount] = useState(0);
  const [roomID, setRoomId] = useState(null);
  let unsubmittedComment = [
    {
      vesselId: null,
      roomId: null,
      customerId: null,
      valueId: null,
      value: null,
      UTCtimeStamp: null,
    },
  ];

  const {
    match: { params },
  } = props;

  const { vid, cid, rid } = params;
  let history = useHistory();
  const Rooms_query = gql`
    query{roomsByVessel(id:${vid},id1:${cid}){name,id,customerId}}`;
  // const { loading, error, data } = useQuery(Rooms_query);
  const [roomsList, setRoomsList] = useState();
  const [getRooms, { loading, error, data }] = useLazyQuery(Rooms_query, {
    fetchPolicy: "cache-and-network",
    onCompleted(resp) {
      setRoomsList(resp);
    },
  });
  let vesselName = localStorage.getItem("vesselName");
  let countApi = useRef(0);
  const SAVE_COMMENT_QUERY = gql`
    mutation saveComment(
      $vesselId: ID!
      $customerId: ID!
      $roomId: ID!
      $commentInput: [CommentInput!]
    ) {
      saveComment(
        vesselId: $vesselId
        customerId: $customerId
        roomId: $roomId
        commentInput: $commentInput
      )
    }
  `;
  useEffect(() => {
    db.unsyncedOperations.toArray().then((unSubmitted) => {
      setUnSubmitted(unSubmitted);
    });
    db.submittedOperations.toArray().then((submitted) => {
      setSubmitted(submitted);
    });


    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  }, [isOnline]);
  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };
  useEffect(() => {
    if (online) {
      getCustMetaData(cid, '', false, vid);
      getRooms();
    } else {
      db.userInfo.toArray().then((users) => {
        let theUser = localStorage.getItem("offline_user_email")
          ? localStorage.getItem("offline_user_email")
          : localStorage.getItem("user_email");
        users.map((user) => {
          if (user.email == theUser) {
            user.response.customersByUser.map((cust) => {
              if (cust.customerId == cid) {
                cust.vessels.map((vessel) => {
                  if (vessel.id == vid) {
                    let data = {
                      roomsByVessel: vessel.rooms,
                    };
                    setRoomsList(data);
                    setQueryCompleted(true);
                  }
                });
              }
            });
          }
        });
      });
    }

    setBgColor("#224691");
    setCount(0);
  }, [online]);

  useEffect(() => {
    // check and delete older from  sync db
    monitorSyncDb();
  }, [todayDate]);

  useEffect(() => {
    // check wether this vessel has any sensor to sync
    checkSync();
  }, [allsubmitted, allUnsubmitted]);

  const monitorSyncDb = () => {
    db.syncedOperations.toArray().then(results=>{
      results.map(result=>{
        let sensorDate = result.valueId.split("-")[2];
        let diff = dateDifference(sensorDate, todayDate);
        if (diff >= 30) {
          db.syncedOperations.delete(result.valueId);
        }
      })
    })
    // cursorForSynced((evt) => {
    //   let cursor = evt.target.result;
    //   if (cursor) {
    //     let sensorDate = cursor.value.valueId.split("-")[2];
    //     let diff = dateDifference(sensorDate, todayDate);
    //     if (diff >= 30) {
    //       deleteSyncedRecord(cursor.value.valueId);
    //     }
    //     cursor.continue();
    //   }
    // });
  };

  const checkSync = () => {
    let needToSync = [...allsubmitted, ...allUnsubmitted];
    let enable = false;

    if (needToSync.length > 0) {
      let count = 0;
      needToSync.forEach((data) => {
        if (vid == data.valueId.split("-")[0]) {
          count++;
          setCount(count);
          enable = true;
          return false;
        } else {
          // setSyncPending(false);
          setCount(count);
          return false;
        }
      });
      setSyncPending(enable);
    } else {
      setSyncPending(false);
    }
  };

  const [updateTodo, { loading: mutationLoading, error: mutationError }] =
    useMutation(ADD_SENSOR, {
      onCompleted(resp) {
        toast.dismiss();
        toast.success("Synced to Triton Successfully!");
        let sensorValues = [];
        db.syncedOperations.toArray().then((synced) => {
          // adding date to unsubmitted
          let localDate = moment().format("L");
          let utcTime = moment().utc().valueOf();
          let allUnsubmittedWithDate = allUnsubmitted.map((unSubmitted) => {
            utcTime = unSubmitted.UTCtimeStamp;
            return {
              value: unSubmitted.value,
              valueId: `${unSubmitted.valueId}-${localDate}`,
              UTCtimestamp: unSubmitted.UTCtimeStamp,
            };
          });
          sensorValues = [
            ...sensorValues,
            ...synced,
            ...allsubmitted,
            ...allUnsubmittedWithDate,
          ];
          sensorValues.forEach((value1, i) => {
            sensorValues.forEach((value2) => {
              if (value1.valueId === value2.valueId) {
                sensorValues[i].value = { ...value1.value, ...value2.value };
              }
            });
          });
          sensorValues.forEach((data, i) => {
            if (vid == data.valueId.split("-")[0]) {
              db.syncedOperations.put({ ...data }, data.valueId).then(
                (event) => {
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          });
         
          db.unsyncedOperations.toArray().then(results=>{
            results.map(result=>{
              if(vid == result.valueId.split("-")[0]){
                db.unsyncedOperations.delete(result.valueId);
              }
            })
          })
          
          setSyncPending(false);
        });
      },
    });

  const goToSensor = (room) => {
    setRoomId(room.id);
    localStorage.setItem("roomName", room.name);
    history.push(`rooms/${room.id}/sensors`);
  };

  

  const syncData = async () => {
    // adding date to unsubmitted
    let localDate = moment().format("L");
    let utcTime;
    let allUnsubmittedWithDate = allUnsubmitted.map((unSubmitted) => {
      utcTime = unSubmitted.UTCtimeStamp;
      return {
        value: unSubmitted.value,
        valueId: `${unSubmitted.valueId}-${localDate}`,
        UTCtimestamp: unSubmitted.UTCtimeStamp,
        correctedTime: unSubmitted.correctedTime, 
        UID:
          unSubmitted.Uid == undefined || unSubmitted.Uid == "undefined"
            ? localStorage.getItem("user_uid")
            : unSubmitted.Uid
      };
    });

    let sensors = [];
    allUnsubmittedWithDate.forEach((data) => {
      if (vid == data.valueId.split("-")[0]) {
        let roomSensors = {
          roomId: data.valueId.split("-")[1],
          sensors: [],
          time: data.UTCtimestamp,
          correctedTime: data.correctedTime,
          uid: data.UID,
        };
        Object.keys(data.value).forEach((key) => {
          roomSensors.sensors.push({
            id: key,
            value: data.value[key],
          });
        });
        if (roomSensors.sensors.length > 0) {
          sensors.push(roomSensors);
        }
      }
    });
    // sync if user have network
    if (isOnline) {
      // taking all tracked(submitted) sensors to sync
      let trackedSensors = [];
      try {
        // trackedSensors = await getTrackedSensors();

        // taking all unSubmitted sensors to sync
        sensors.forEach((sensorItem) => {
          trackedSensors.push({
            time: sensorItem.time,
            correctedTime: sensorItem.correctedTime,
            roomId: +sensorItem.roomId,
            sensors: sensorItem.sensors,
            uId: sensorItem.uid,
          });
        });
      } catch (error) {
        console.log("eerr", error);
      }

      try {
        // check any sensor values needs to be sync
        if (trackedSensors.length > 0) {
          await updateTodo({
            variables: {
              vesselId: vid,
              customerId: cid,
              sensorInput: trackedSensors,
            },
          });
        } else {
          toast.dismiss();
          toast.warning("Nothing to Sync!");
        }
      } catch (e) {
        toast.dismiss();
        toast.error("Daily Round Not Submitted!");
      }
    }
  };

  const roomsQueryCompleted = () => {
    countApi.current++;
    if (data.roomsByVessel.length == countApi.current) {
      setQueryCompleted(true);
    }
  };

  return (
    <div className="customers">
      <h5>TRITON OPS</h5>
      <div className="title">
        <h4 className="welcome">
          This is <strong>{vesselName}</strong>
        </h4>
        <p className="question">Select one of the following actions</p>
      </div>
      <div className="overflow">
        {data &&
          data.roomsByVessel &&
          data.roomsByVessel.length !== 0 &&
          !queryCompleted && (
            <div className="loader">
              {" "}
              <Loader
                type="TailSpin"
                color=" #2546B1"
                height={80}
                width={80}
                timeout={300000000000000}
              />{" "}
            </div>
          )}
        {mutationError && <Messages data={mutationError} />}
        {!!!mutationError && error && <Messages data={error} />}
        {loading && (
          <div className="loader">
            <Loader
              type="TailSpin"
              color=" #2546B1"
              height={80}
              width={80}
              timeout={300000000000000000}
            />
          </div>
        )}
        {roomsList &&
          roomsList.roomsByVessel &&
          roomsList.roomsByVessel.length === 0 && <p>No rooms to list!</p>}
        {!isOnline && !!!roomsList && !loading && (
          <span className="msg-content">
            Content is not loading since app has gone offline..
          </span>
        )}
        <Container>
          <Row>
            {roomsList &&
              roomsList.roomsByVessel &&
              roomsList.roomsByVessel.map((room, index) => (
                <Col
                  xs={6}
                  key={room.id}
                  onClick={() => queryCompleted && goToSensor(room)}
                >
                  {/* {online && !formdetails && (
                    <Query
                      client={client}
                      onCompleted={() => roomsQueryCompleted(index)}
                      query={gql`query{componentsVirtualSensorByVesselRoom(vesselId:${vid},roomId:${room.id},customerId:${cid}){name,id,Sensor{id,name,properties{type,options}}}}`}
                    >
                      {({ loading, error, data: formsData }) => {
                        if (data.roomsByVessel.length == index + 1) {
                          formdetails = true;
                        }
                        return null;
                      }}
                    </Query>
                  )} */}
                  {
                    <div className="room-box">
                      <Container>
                        <Row>
                          <Col xs={12}>
                            <div className="sec-title sec-ellipsis" title={room.name}>{room.name}</div>
                            {/* <ProgressBar variant="success" /> */}
                            {/* <div className="status">0 of 4 completed</div> */}
                            <div className="room-img">
                              {" "}
                              <img src={roomImg} alt="room" />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  }
                </Col>
              ))}
          </Row>
          {isOnline ? (
            <button
              className="sync-btn"
              onClick={syncData}
              disabled={!syncPending}
            >
              {mutationLoading ? (
                <Loader
                  type="ThreeDots"
                  color=" #fff"
                  height={50}
                  width={50}
                  timeout={3000000000000}
                />
              ) : (
                "Sync to Triton"
              )}
            </button>
          ) : (
            <span className="offline-span">
              Device is offline. {count} record(s) to sync
            </span>
          )}
        </Container>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </div>
  );
};

export default Rooms;
