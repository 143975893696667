import React, { useEffect, useState } from "react";
import OperatorInputText from "./TextField.js";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ToggleSwitch from "./Switch";
import OperatorRadio from "./Radio";
import FileUpload from "./FileUpload.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Icon } from "@iconify/react";
const InputField = (props) => {
  const {
    register,
    inputProps,
    values,
    control,
    setValue,
    disabled,
    liveData,
    params,
    componentId,
    setUploadFiles,
    selectedDate,
    prevDateTime,
  } = props;
  // const [toggleChecked1, setToggleChecked] = useState(false)
  const [formValues, setformValues] = useState({});
  const [liveValue, setLiveValue] = useState();
  useEffect(() => {
    if (Object.entries(values).length) {
      Object.entries(values).forEach(([name, value]) => {
        setValue(name, value);
        setformValues((prevData) => {
          return { ...prevData, ...{ [name]: value } };
        });
      });
    } else {
      setformValues({});
    }
  }, [values]);

  useEffect(()=>{

  }, [formValues])

  useEffect(() => {
    if (inputProps.sensorId && liveData.sensorval && liveData.sensorval[inputProps.sensorId] !== undefined) {
      setLiveValue(liveData.sensorval[inputProps.sensorId].toFixed(2).toString());
    } else if (inputProps.sensorId && liveData.errorval && liveData.errorval[inputProps.sensorId] !== undefined) {
      setLiveValue('Error')
    }
  }, [liveData])

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setformValues((prevData) => {
      return { ...prevData, ...{ [name]: value } };
    });
   
  };

  const handleUpload = (data) => {   
    setUploadFiles({ id: data.name, value: data.value, data: data })
  };

  const toggleChecked = (e) => {
    const { name, value, checked } = e.target;
    setformValues((prevData) => {
      return { ...prevData, ...{ [name]: checked ? "true" : "false" } };
    });
  };

  return (
    <>

      {inputProps.properties.type === "Text Field" ? (
        <FormControl
          component="fieldset"
          style={{ display: "flex", marginTop: ".6rem" }}
        >
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ fontSize: "17pt" }}
          >
            {inputProps.name}
          </InputLabel>
          <OperatorInputText
            name={inputProps.id}
            disabled={disabled}
            inputRef={register}
            value={formValues[inputProps.id] ? formValues[inputProps.id] : ""}
            onChange={handleChange}
            inputProps={{ autoComplete: "off" }}
            id="bootstrap-input"
          />
        </FormControl>
      ) : inputProps.properties.type === "Toggle Button" ? (
        <FormControl
          component="fieldset"
          style={{ marginTop: ".6rem", display: "flex", width: "100%" }}
        >
          <FormControlLabel
            control={
              <ToggleSwitch
                name={inputProps.id}
                disabled={disabled}
                value={formValues[inputProps.id] == "true" ? "true" : "false"}
                onChange={toggleChecked}
                checked={formValues[inputProps.id] == "true" ? true : false}
                inputRef={register}
              />
            }
            labelPlacement="start"
            name={inputProps.name.replace(".", "")}
            label={inputProps.name}
            style={{
              marginLeft: "3px",
              fontSize: "1rem",
              color: "rgba(0, 0, 0, 0.54)",
              justifyContent: "space-between",
              wordBreak: "break-all",
            }}
          />
        </FormControl>
      ) : inputProps.properties.type === "Radio Button Group" ? (
        <FormControl
          component="fieldset"
          style={{ display: "flex", marginTop: ".6rem", position: "unset" }}
        >
          <FormLabel
            component="legend"
            style={{ fontSize: "1rem", marginBottom: 0 }}
          >
            {inputProps.name}
          </FormLabel>
          <RadioGroup
            row
            name={inputProps.id}
            value={formValues[inputProps.id] ? formValues[inputProps.id] : ""}
            onChange={handleChange}
          >
            {inputProps.properties.options.map((option, i) => (
              <FormControlLabel
                key={i}
                value={option}
                disabled={disabled}
                control={<OperatorRadio inputRef={register} />}
                label={option}
                style={{ marginRight: "10%" }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      ) : inputProps.properties.type === "File Upload" ? (
        <FormControl
          component="fieldset"
          style={{ display: "flex", marginTop: ".6rem", position: "unset" }}
        >
          <FormLabel
            component="legend"
            style={{ fontSize: "1rem", marginBottom: 0 }}
          >
            {inputProps.name}
          </FormLabel>
          <FileUpload
            value={formValues[inputProps.id] ? formValues[inputProps.id] : ""}
            name={inputProps.id}
            data={params}
            props={inputProps}
            componentId={componentId}
            onChange={handleUpload}
            selectedDate={selectedDate}
            prevDateTime={prevDateTime}
          ></FileUpload>
        </FormControl>
      ) : (
        ""
      )}
      {liveValue == "Error" ? (
        <>
          <span className="live-val mb-3 d-block">
            Live Value:{" "}
            <Icon
              icon="ion:warning"
              className="text-warning"
              style={{ fontSize: "18px" }}
            />
          </span>{" "}
        </>
      ) : null}
      {liveValue && liveValue !== "Error" ? (
        <span className="live-val mb-3 d-block">Live Value: {liveValue}</span>
      ) : null}
    </>
  );
};

export default InputField;
