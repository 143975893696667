import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import config from "../config";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../helpers/authConfig";
import { msalInstance } from "../containers/App";
import jwt_decode from "jwt-decode";
// let msalInstance;

/*istanbul ignore next */
fetch("/appConfig/msalContextpath.json", {
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "Accept": "application/json",
  },
})
  .then((res) => res.json())
  .then((data) => {
    localStorage.setItem('appConfig', JSON.stringify(data))
    // msalInstance = new PublicClientApplication(msalConfig);
  }).catch(error=>{
    console.log(error)
  });

// const msalInstance = new PublicClientApplication(msalConfig);
const httpLink = new HttpLink({ uri: `${config.graphQlendpoint}` });
// let user;
const authMiddleware = new ApolloLink(async (operation, forward) => {
  // const userEmail = localStorage.getItem("user_email");
  // const encryptedEmail = userEmail !== null ? btoa(userEmail) : null;

  let token = await getToken();
  let access_decode = jwt_decode(token.accessToken);
  // add the authorization to the headers
  operation.setContext({
    headers: {
      "Authorization": "Bearer " + token.accessToken,
      "Authorization-Id": token.idToken,
      "User-Email": btoa(access_decode.email ? access_decode.email : access_decode.unique_name),
      "Application": btoa(token.application),
      "Session-Id": token.sessionId
    },
  });

  return forward(operation);
});




const logoutLink = onError(({ graphQLErrors, networkError }) => {

  if (graphQLErrors) {
    console.log("graphQLErrors", graphQLErrors);
    let errorCode =
      graphQLErrors[0].extensions && graphQLErrors[0].extensions.code;
    if (errorCode === "UNAUTHENTICATED") {
      console.log('REAUTH')

    }
    if (errorCode == "TOKEN_EXPIRED") {

    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const getToken = async () => {
  const account = msalInstance.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: ["User.Read"],
    account: account,
  };

  const token = await msalInstance
    .acquireTokenSilent(accessTokenRequest)
    .then(function (accessTokenResponse) {
      // Acquire token silent success
      let accessToken = accessTokenResponse.accessToken;
      let idToken = accessTokenResponse.idToken;
      let sessionId = accessTokenResponse.account.idTokenClaims.sid;
      let application = accessTokenResponse.account.idTokenClaims.aud;
      let tokens = {
        accessToken,
        idToken,
        sessionId,
        application
      }
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('application', application)
      return tokens
      // Call your API with token
    })
    .catch(function (error) {
      //Acquire token silent failure, and send an interactive request
      if (error) {
        msalInstance
          .acquireTokenPopup(accessTokenRequest)
          .then(function (accessTokenResponse) {
            // Acquire token interactive success
            let accessToken = accessTokenResponse.accessToken;
            let idToken = accessTokenResponse.idToken;
            let sessionId = accessTokenResponse.account.idTokenClaims.sid;
            let application = accessTokenResponse.account.idTokenClaims.aud;
            let tokens = {
              accessToken,
              idToken,
              sessionId,
              application
            }
            return tokens
            // Call your API with token
          })
          .catch(function (error) {
            // Acquire token interactive failure
            console.log('reauth failed', error);
            msalInstance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
          });
      }
      console.log(error);
    });

  return token
}

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, logoutLink, httpLink]),
});
