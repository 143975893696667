import React, { useState, useEffect, useCallback, useRef } from "react";
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import "./Comments.scss";
import { AiFillCamera } from "react-icons/ai";
import { IoMdCloudDownload } from "react-icons/io";
import { IoMdSend, IoMdPhotos } from "react-icons/io";
import ImageViewer from "react-simple-image-viewer";
import imageCompression from "browser-image-compression";
import Loader from "react-loader-spinner";
import moment from "moment";
import { postComments, getComments, getImage } from "./CommentService";
import blur from "../../assets/blur.jpg";
import useNetwork from "../../customHooks/useNetwork";
import { IoMdCloseCircle } from "react-icons/io";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RadioButton } from "primereact/radiobutton";
import { ToastContainer, toast } from "react-toastify";
import db from "../../DexieDBConfig";

/*istanbul ignore next */
const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
  const image = new Image();
  image.src = imageSrc;
  await new Promise((resolve) => {
    image.onload = resolve;
  });
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;

  ctx.drawImage(
    image,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error("Canvas is empty"));
        return;
      }
      blob.name = "croppedImage.png";
      resolve(blob);
    }, "image/png");
  });
};

let images = [];

const ChatInterface = (props) => {
  const vid = props.vid;
  const rid = props.rid;
  const cid = props.cid;
  const selectedDate = props.selectedDate;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [initialCroppedArea, setInitialCroppedArea] = useState(undefined);
  const [showCropper, setShowCropper] = useState(false);
  const [zoom, setZoom] = useState(0);
  const [imageSrc, setImageSrc] = useState(null);
  const [comment, setMessage] = useState("");
  const [chat, setChat] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [loader, showLoader] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(4 / 3);
  const [initialAspectRatio, setInitialAspectRatio] = useState(4 / 3);

  const [comments, setComments] = useState("");
  const [imgFile, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [diffDays, setDiffDays] = useState(0);
  const [error, setError] = useState(false);
  const networkState = useNetwork();
  const { online } = networkState;
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const chatEndRef = useRef(null);

  useEffect(async () => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
  }, []);

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setDiffDays(moment(new Date()).diff(new Date(props.selectedDate), "days"));
    setChat([]);
    setImageSrc(null);
    setShowCropper(false);
    setFileName("");
    setZoom(1);
    if (online) {
      getChats();
    } else {
      const id = `${cid}-${vid}-${rid}-${moment(props.selectedDate).format(
        "YYYY-MM-DD"
      )}`;

      db.comments.toArray().then((result) => {
        result.map((each) => {
          if (each.id == id) {
            setChat(each.data);
            setTimeout(() => {
              scrollToBottom();
            }, 1000);
          }
        });
      });
    }
  }, [props.selectedDate]);

  const getChats = async () => {
    setChat([]);
    try {
      const result = await getComments(
        cid,
        vid,
        rid,
        moment(new Date(props.selectedDate)).startOf("day").valueOf(),
        moment(new Date(props.selectedDate)).endOf("day").valueOf(),
        atob(localStorage.getItem("fnKey"))
      );
      if (result) {
        setChat(result);
        setTimeout(() => {
          scrollToBottom();
        }, 1000);
      }

      if (result && result.length > 0) {
        const id = `${cid}-${vid}-${rid}-${moment(props.selectedDate).format(
          "YYYY-MM-DD"
        )}`;
        const record = {
          id,
          data: result,
        };
        db.comments.toArray().then((result) => {
          let found = false;
          result.map((each) => {
            if (each.id == id) {
              found = true;
              db.comments.delete(id).then(() => {
                db.comments.add(record, id).then(() => {});
              });
            }
          });
          if (!found) {
            db.comments.add(record, id).then(() => {});
          }
        });
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      toast.dismiss();
      toast.error("Error getting messages!");
    } finally {
      setLoading(false);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFileName(file.name);
      let imageDataUrl = await readFile(file);
      let img = new Image();
      img.src = imageDataUrl;
      img.addEventListener(
        "load",
        (event) => {
          let aspectRatio =
            event.currentTarget.naturalWidth /
            event.currentTarget.naturalHeight;
          setInitialAspectRatio(aspectRatio);
          setAspectRatio(aspectRatio);
          setImageSrc(imageDataUrl);
          setZoom(1);
          setShowCropper(true);
        },
        false
      );
    }
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };
/*istanbul ignore next */
  const handleSendMessage = async (event) => {
    event.preventDefault();
    if (comment.trim() || imageSrc) {
      let id = `${vid}-${rid}-${moment().utc().valueOf()}`;

      if (comment) {
        const data = new FormData();
        data.append("comment", comment);
        data.append("time", moment.utc(new Date()).valueOf());
        if (online) {
          postData(data).then(() => {
            setTimeout(() => {
              if (!error) {
                setChat([...chat, { comment, imageSrc: null, image: false }]);
              }
            }, 1000);

            setMessage("");
            setImageSrc(null);
            setShowCropper(false);
            setFileName("");
            setTimeout(() => {
              scrollToBottom();
            }, 1000);
          });
        } else {
          const fileRecord = {
            id: id,
            time: moment.utc(new Date()).valueOf(),
            type: "string",
            content: comment,
            cid: cid,
            vid: vid,
            rid: rid,
            imgType: "NA",
          };
          db.files.add(fileRecord, id).then(
            (event) => {
              setChat([...chat, { comment, imageSrc: null, image: false }]);
              setMessage("");
              setImageSrc(null);
              setShowCropper(false);
              setFileName("");

              db.comments.toArray().then((result) => {
                const id = `${cid}-${vid}-${rid}-${moment(
                  props.selectedDate
                ).format("YYYY-MM-DD")}`;
                let found = false;
                result.map((each) => {
                  if (each.id == id) {
                    found = true;
                    let entry = {
                      comment: comment,
                      createdBy: localStorage.getItem("user_name"),
                      image: false,
                      time: moment.utc(new Date()).valueOf(),
                    };
                    each.data.push(entry);
                    db.comments.delete(id).then(() => {
                      db.comments.add(each, id).then(() => {});
                    });
                  }
                });
                if (!found) {
                  let entry = {
                    comment: comment,
                    createdBy: localStorage.getItem("user_name"),
                    image: false,
                    time: moment.utc(new Date()).valueOf(),
                  };
                  const record = {
                    id,
                    data: [entry],
                  };
                  db.comments.add(record, id).then(() => {});
                }
              });
              setTimeout(() => {
                scrollToBottom();
              }, 1000);
            },
            (error) => {
              console.error("Error adding file:", error);
            }
          );
        }
      } else {
        let croppedImageUrl = null;
        if (imageSrc && showCropper) {
          showLoader(true);
          const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);

          // Compress the cropped image before uploading
          const compressedBlob = await imageCompression(croppedBlob, {
            maxSizeMB: 5,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          });

          croppedImageUrl = URL.createObjectURL(compressedBlob);
          showLoader(false);
          const data = new FormData();
          let file = new File([compressedBlob], imgFile, {
            type: compressedBlob.type,
          });
          let base64url = await blobToBase64(compressedBlob);
          data.append("image", file);
          data.append("time", moment.utc(new Date()).valueOf());
          if (online) {
            postData(data).then(() => {
              setTimeout(() => {
                if (!error) {
                  setChat([
                    ...chat,
                    { comment, imageSrc: croppedImageUrl, image: true },
                  ]);
                }
              }, 1000);

              setMessage("");
              setImageSrc(null);
              setShowCropper(false);
              setFileName("");
              setTimeout(() => {
                scrollToBottom();
              }, 1000);
            });
          } else {
            const fileRecord = {
              id: id,
              time: moment.utc(new Date()).valueOf(),
              type: "file",
              content: base64url,
              cid: cid,
              vid: vid,
              rid: rid,
              imgType: compressedBlob.type,
            };
            db.files.add(fileRecord, id).then(
              (event) => {
                setChat([
                  ...chat,
                  { comment, imageSrc: croppedImageUrl, image: true },
                ]);
                setMessage("");
                setImageSrc(null);
                setShowCropper(false);
                setFileName("");
                db.comments.toArray().then((result) => {
                  const id = `${cid}-${vid}-${rid}-${moment(
                    props.selectedDate
                  ).format("YYYY-MM-DD")}`;
                  let found = false;
                  result.map((each) => {
                    if (each.id == id) {
                      found = true;
                      let entry = {
                        comment: null,
                        createdBy: localStorage.getItem("user_name"),
                        image: true,
                        time: moment.utc(new Date()).valueOf(),
                      };
                      each.data.push(entry);
                      db.comments.delete(id).then(() => {
                        db.comments.add(each, id).then(() => {});
                      });
                    }
                  });
                  if (!found) {
                    let entry = {
                      comment: null,
                      createdBy: localStorage.getItem("user_name"),
                      image: true,
                      time: moment.utc(new Date()).valueOf(),
                    };
                    const record = {
                      id,
                      data: [entry],
                    };
                    db.comments.add(record, id).then(() => {});
                  }
                });
                setTimeout(() => {
                  scrollToBottom();
                }, 1000);
              },
              (error) => {
                console.error("Error adding file:", error);
              }
            );
          }
        }
      }
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const postData = async (comment) => {
    setLoading(true);
    setError(false);
    try {
      const result = await postComments(
        cid,
        vid,
        rid,
        comment,
        atob(localStorage.getItem("fnKey"))
      );
      setComments(result);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError(true);
      toast.dismiss();
      toast.error("Error sending message! Please try again.");
    } finally {
      setLoading(false);
    }
  };
/*istanbul ignore next */
  const downloadImage = async (message) => {
    if (online) {
      setLoading(true);
      setError(false);
      try {
        const result = await getImage(
          cid,
          vid,
          rid,
          message.time,
          atob(localStorage.getItem("fnKey"))
        );
        message.imageSrc = result[0];
        const id = `${cid}-${vid}-${rid}-${message.time}`;
        const record = {
          id,
          data: result[0],
          time: moment(new Date()).valueOf(),
        };
        // storeImage(record, id);
        db.images.add(record, id);
      } catch (error) {
        setError(true);
        console.error("Failed to fetch data:", error);
        toast.dismiss();
        toast.error("Error downloading image!");
      } finally {
        setLoading(false);
      }
    }
  };
/*istanbul ignore next */
  const openImageViewer = useCallback((index, image) => {
    images[0] = image;
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  /*istanbul ignore next */
  const checkOfflineImage = (message) => {
    db.images.toArray().then((images) => {
      images.map((image) => {
        if (image.id == `${cid}-${vid}-${rid}-${message.time}`) {
          message.imageSrc = image.data;
        }
      });
      setChat([...chat]);
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    });

    // getAllImages().then((images) => {
    //   images.map((image) => {
    //     if (image.id == `${cid}-${vid}-${rid}-${message.time}`) {
    //       message.imageSrc = image.data;
    //     }
    //   });
    //   setChat([...chat]);
    //   setTimeout(() => {
    //     scrollToBottom();
    //   }, 1000);
    // });
  };

  const closeCropper = () => {
    setImageSrc(null);
    setShowCropper(false);
    setFileName("");
    setZoom(1);
  };
  /*istanbul ignore next */
  return (
    <div className="chat-container">
      {loading ? (
        <div className="chat-loader">
          <Loader
            type="TailSpin"
            color="#2546B1"
            height={100}
            radius={2}
            width={100}
            timeout={300000}
          />
        </div>
      ) : null}
      <div className="chat-messages pb-3">
        {chat.map((chatItem, index) => (
          <div key={index} className="chat-message">
            {chatItem.comment && (
              <>
                <div className="message-bubble">
                  <div className="chat-info text-left">
                    {chatItem.createdBy
                      ? chatItem.createdBy
                      : localStorage.getItem("user_name")}
                  </div>
                  <div className="text-content text-left">
                    {chatItem.comment}
                  </div>
                  <div className="chat-info text-right">
                    {moment(chatItem.time).format("HH:mm")}
                  </div>
                </div>
              </>
            )}
            {chatItem.image && !chatItem.imageSrc && (
              <>
                <div
                  className="message-bubble blur-img"
                  onClick={() => downloadImage(chatItem)}
                >
                  <div className="chat-info text-left mb-1">
                    {chatItem.createdBy
                      ? chatItem.createdBy
                      : localStorage.getItem("user_name")}
                  </div>
                  <div className="text-left">
                    <img
                      src={blur}
                      onLoad={() => checkOfflineImage(chatItem)}
                    />
                  </div>
                  <div className="chat-info text-right mt-1">
                    {moment(chatItem.time).format("HH:mm")}
                  </div>
                </div>
              </>
            )}
            {chatItem.imageSrc && (
              <div className="message-bubble">
                <div className="chat-info text-left mb-1">
                  {chatItem.createdBy
                    ? chatItem.createdBy
                    : localStorage.getItem("user_name")}
                </div>
                <img
                  src={chatItem.imageSrc}
                  onClick={() => openImageViewer(0, chatItem.imageSrc)}
                  alt="Uploaded"
                  className="uploaded-image"
                />
                <div className="chat-info text-right mt-1">
                  {moment(chatItem.time).format("HH:mm")}
                </div>
              </div>
            )}
            {isViewerOpen && (
              <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </div>
        ))}
        <div ref={chatEndRef}></div>
      </div>
      {showCropper ? (
        <div className="cropper-container">
          <div className="crop-container">
            <Cropper
              data-testid="test-crop"
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              objectFit="contain"
            />
          </div>
          <div className="controls">
            <p>Zoom: </p>
            <Slider
              value={zoom}
              min={1}
              max={8}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              classes={{ container: "slider" }}
            />
          </div>
          <div className="radio-box mb-2">
            <p>Aspect ratios: </p>
            <div className="row wid-250 text-left">
              <div className="col-6">
                <RadioButton
                  value="1:1"
                  name="aspectRatio"
                  checked={aspectRatio === 1}
                  onChange={(e) => setAspectRatio(1)}
                />
                <span className="pl-1">Square</span>
              </div>

              <div className="col-6">
                <RadioButton
                  value="1:1.414"
                  name="aspectRatio"
                  checked={aspectRatio === 1 / 1.414}
                  onChange={(e) => setAspectRatio(1 / 1.414)}
                />
                <span className="pl-1">Document</span>
              </div>
            </div>
            <div className="row wid-250 mt-2 text-left">
              <div className="col-6">
                <RadioButton
                  value="16:9"
                  name="aspectRatio"
                  checked={aspectRatio === 16 / 9}
                  onChange={(e) => setAspectRatio(16 / 9)}
                />
                <span className="pl-1">Wide</span>
              </div>
              <div className="col-6">
                <RadioButton
                  value={initialAspectRatio}
                  name="aspectRatio"
                  checked={aspectRatio === initialAspectRatio}
                  onChange={(e) => setAspectRatio(initialAspectRatio)}
                />
                <span className="pl-1">Full</span>
              </div>
            </div>
          </div>
          {!loader ? (
            <div>
              <IoIosCheckmarkCircle
                onClick={(e) => handleSendMessage(e)}
                className="crop-button"
              />
              <IoMdCloseCircle
                onClick={(e) => closeCropper(e)}
                className="cancel-button"
              />
            </div>
          ) : (
            <Loader
              type="TailSpin"
              color=" #2546B1"
              height={35}
              width={35}
              timeout={300000}
            />
          )}
        </div>
      ) : diffDays == 0 ? (
        <div className="chat-input">
          <input
            type="text"
            value={comment}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type a message..."
            className="message-input"
          />
          {comment.length == 0 ? (
            <>
              <input
                type="file"
                onChange={onFileChange}
                accept="image/*"
                className="file-input"
                id="file-input-cam"
              />
              <label htmlFor="file-input-cam" className="camera-icon">
                <IoMdPhotos />
              </label>
              &nbsp;
              <input
                type="file"
                capture="camera"
                onChange={onFileChange}
                accept="image/*"
                className="file-input"
                id="file-input"
                data-testid="test-input"
              />
              <label htmlFor="file-input" className="camera-icon">
                <AiFillCamera />
              </label>
            </>
          ) : null}
          &nbsp;
          <label className="send-icon">
            <IoMdSend
              onClick={(e) => handleSendMessage(e)}
              data-testid="upload-btn"
            />
          </label>
        </div>
      ) : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </div>
  );
};

export default ChatInterface;
